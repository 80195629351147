import React, { useState, useEffect } from 'react';
import { Moon, Sun, Zap, Package, User, Clock, Laptop, Globe, Film, Download } from 'lucide-react';

interface RedirectLink {
  name: string;
  url: string;
  icon: JSX.Element;
}

const localLinks: RedirectLink[] = [
  { name: 'Nextcloud', url: 'http://192.168.1.73', icon: <Sun className="w-6 h-6" /> },
  { name: 'Open WebUI', url: 'http://192.168.1.73:44445', icon: <Zap className="w-6 h-6" /> },
  { name: 'Jellyfin', url: 'http://192.168.1.73:8096', icon: <Film className="w-6 h-6" /> },
  { name: 'Deluge', url: 'http://192.168.1.73:8112', icon: <Download className="w-6 h-6" /> },
];

const publicLinks: RedirectLink[] = [
  { name: 'Nextcloud', url: 'https://nextcloud.hijoseph08.lol/', icon: <Sun className="w-6 h-6" /> },
  { name: 'Open WebUI', url: 'https://open-webui.hijoseph08.lol/', icon: <Zap className="w-6 h-6" /> },
  { name: 'Inventory Dev', url: 'https://inventory-dev.hijoseph08.lol/', icon: <Package className="w-6 h-6" /> },
  { name: 'Portfolio', url: 'https://josephcheng-portfolio.netlify.app/', icon: <User className="w-6 h-6" /> },
  { name: 'Tile Countdown', url: 'https://tile-count-down-timer.netlify.app', icon: <Clock className="w-6 h-6" /> },
];

const LinkCard: React.FC<{ link: RedirectLink; isHovered: boolean; onHover: () => void; onLeave: () => void }> = ({ link, isHovered, onHover, onLeave }) => (
  <a
    href={link.url}
    className="group relative p-6 bg-gray-800 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:rotate-3 hover:bg-gray-700"
    onMouseEnter={onHover}
    onMouseLeave={onLeave}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div className="flex items-center justify-center mb-4">
      {link.icon}
    </div>
    <h2 className="text-xl font-semibold mb-2">{link.name}</h2>
    {isHovered && (
      <p className="text-sm text-gray-400 absolute bottom-2 left-0 right-0">
        Click to teleport! 🚀
      </p>
    )}
  </a>
);

const FunnyRedirectPage: React.FC = () => {
  const [hoveredIndex, setHoveredIndex] = useState<{ category: string; index: number } | null>(null);

  useEffect(() => {
    document.body.classList.add('bg-gray-900');
    return () => document.body.classList.remove('bg-gray-900');
  }, []);

  const renderLinkSection = (title: string, links: RedirectLink[], category: string) => (
    <div className="mb-12">
      <h2 className="text-2xl font-bold mb-4 flex items-center justify-center">
        {title === "Local" ? <Laptop className="mr-2" /> : <Globe className="mr-2" />}
        {title}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {links.map((link, index) => (
          <LinkCard
            key={index}
            link={link}
            isHovered={hoveredIndex?.category === category && hoveredIndex.index === index}
            onHover={() => setHoveredIndex({ category, index })}
            onLeave={() => setHoveredIndex(null)}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white p-8">
      <div className="text-center max-w-6xl w-full">
        <h1 className="text-4xl font-bold mb-8 animate-pulse">
          Welcome to Joseph's Wacky Web World! 🌙✨
        </h1>
        {renderLinkSection("Local", localLinks, "local")}
        {renderLinkSection("Public", publicLinks, "public")}
        <div className="mt-12 animate-bounce">
          <Moon className="w-12 h-12 mx-auto" />
        </div>
      </div>
    </div>
  );
};

export default FunnyRedirectPage;